window.am = window.am || {};

window.am.cookie = new function ()
{
    var that = this;

    that.create = function (name, value, months, shouldEscape)
    {
        /* set default value if not set */
        shouldEscape = shouldEscape !== false;

        /* Ensure a bool value */
        shouldEscape = toBool(shouldEscape);

        var date = new Date();
        date.setMonth(date.getMonth() + months);
        var dateStr = date.toUTCString();

        var escapedValue = shouldEscape ? escape(value) : value;

        document.cookie = escape(name) + '=' + escapedValue +
            '; expires=' + dateStr + '; path=/';
    };

    that.get = function (cname, shouldUnescape)
    {
        shouldUnescape = shouldUnescape !== false;
        shouldUnescape = toBool(shouldUnescape);

        var name = cname + "=";
        var ca = document.cookie.split(';');

        for (var i = 0; i < ca.length; i++)
        {
            var c = ca[i];

            while (c.charAt(0) == ' ')
            {
                c = c.substring(1);
            }

            if (c.indexOf(name) == 0)
            {
                if (shouldUnescape)
                {
                    return unescape(c.substring(name.length, c.length));
                }

                return c.substring(name.length, c.length);
            }
        }

        return '';
    };

    that.remove = function (cname)
    {
        document.cookie = cname + '=; expires=Thu, 01 Jan 1970 00:00:01 GMT;';
    };

    function toBool(value)
    {
        if (!isUndefinedOrNull(value) &&
            value.toString().toLowerCase() == "true")
        {
            return true;
        }

        return false;
    };

    function isUndefinedOrNull (obj)
    {
        return typeof obj === "undefined" || obj == null;
    };

};